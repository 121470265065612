import { theme as proTheme } from '@chakra-ui/pro-theme'
import { extendTheme } from '@chakra-ui/theme-utils'
import { components } from './components'
import * as foundations from './foundations'
import { styles } from './styles'

export const theme: any = extendTheme(proTheme, {
  styles,
//   ...foundations,
//   ...foundations.typography,
  colors: { 
    ...foundations.colors, 
    // brand: foundations.colors.yellow
    custom: {
      100: "#d7cbb4",
      200: "#8B0000"
    }
  },

//   components: {
//     ...components,
//   },
})
