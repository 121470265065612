// src/pages/Home.tsx
import React from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  Button,
  Image,
  AspectRatio,
  SimpleGrid,
  Stack,
  Link as ChakraLink,
  Center,
  Flex
} from "@chakra-ui/react";



import { StatsWithNumbers } from "../components/StatsWithNumbers";
import { StoriesWithAccordion } from "../components/StoriesWithAccordion";
import { Opportunities } from "../components/Opportunities";
import { NavBarLogic } from "../components/NavBarLogic";
import { Footer } from "../components/Footer";
import { useName } from "../contexts/NameContext";
import { CountdownTimer } from "../components/CountdownTimer";
import { AboutUs } from "../components/AboutUs";
import { Sponsors } from "../components/Sponsors";
import { News } from "../components/News";
import { Events } from "../components/Events";

export const HomePage = () => {
  const { name } = useName();

  return (
    <>
      <NavBarLogic ></NavBarLogic>




      <Box as="section" id="hero" bg="custom.100">
        <>
          <Image
            alt="Placeholder Image"
            src="../assets/landing_page_image.webp"
            objectFit="cover"
            // objectPosition="center -140px"
            // maxH={{ base: 'sm', md: 'lg' }}
            width="full"
          />

          {/* <Box id="timer">
            <CountdownTimer />
          </Box> */}



          <Container mt={5}>
            <Stack spacing={{ base: '4', md: '6' }} align="center" textAlign="center">
              <Stack spacing="3">
                {/* <Text fontSize={{ base: 'sm', md: 'md' }} fontWeight="medium" >
                  Pricing
                </Text> */}
                <Heading size={{ base: 'md', md: 'lg' }} fontWeight="semibold">
                  Own Property in Byron Bay, Australia
                </Heading>
              </Stack>
              <Heading size={{ base: 'sm', md: 'md' }}>Empower Your Land Stewardship</Heading>
              <Text color="fg.accent.muted" fontSize={{ base: 'lg', md: 'xl' }} maxW="3xl">
                Unlock the potential to own and manage prime real estate in Byron Bay with our advanced property stewardship tools. Our platform leverages the power of blockchain to offer a seamless, secure, and transparent method to own land.
              </Text>
            </Stack>
          </Container>
        </>


      </Box>




      <Box as="section" id="about">
        {/* <StoriesWithAccordion /> */}
        <AboutUs />


      </Box>

      <Box >
        {/* <StatsWithNumbers /> */}
        {/* <Center> */}

        {/* <Flex justifyContent="center" alignItems="center" w="100%" h="100%"> Centers content both horizontally and vertically */}

        {/* </Flex> */}
        {/* </Center> */}
      </Box>



      {/* <Box id="sponsors"> */}
      {/* <Opportunities /> */}
      {/* <Sponsors /> */}
      {/* </Box> */}

      {/* <Box id="news"> */}
      {/* <Opportunities /> */}
      {/* <News /> */}
      {/* </Box> */}

      {/* <Box id="events">
        
        <Events />
      </Box> */}

      <Box id="footer">
        <Footer />


      </Box>
    </>
  );
};
