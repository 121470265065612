import { HTMLChakraProps, chakra, Image } from "@chakra-ui/react";
import { useName } from "../contexts/NameContext";
import logo from "../branding/byronbaytokenlogo656x696.png";

export const Logo = (props: HTMLChakraProps<"img">) => {
  // Since the name is not being used here, we can remove the useName hook
  // const { name } = useName();



  return (
    <Image
      src={logo}
      alt="Aussie AI & Web3 Logo"
      height="50px" // Adjust height as needed
      width="auto" // This will maintain the aspect ratio of the image
      {...props}
    />
  );
};




//   return (
//     <chakra.svg
//       height="50" // Adjust height as needed
//       width="200" // Adjust width as needed
//       viewBox="0 0 200 50" // Adjust viewBox based on the actual size and layout you need
//       xmlns="http://www.w3.org/2000/svg"
//       {...props}
//     >
//       <style>
//         {`
//           .aussie-text { font: bold 16px sans-serif; fill: #E53E3E; } // Example color, change as you like
//           .ai-web3-text { font: bold 16px sans-serif; fill: #2B6CB0; } // Example color, change as you like
//         `}
//       </style>
//       {/* Aussie text */}
//       <text x="10" y="20" className="aussie-text">
//         Aussie
//       </text>
//       {/* AI & Web3 text, adjust the positioning and font-size as necessary */}
//       <text x="10" y="40" className="ai-web3-text">
//         AI & Web3
//       </text>
//     </chakra.svg>
//   );
// };
