import { Button, Stack, Text, Box, Container, Heading, Spacer, Image, Center, Link as ChakraLink } from '@chakra-ui/react'
import { FiArrowRight } from 'react-icons/fi'

// interface StatProps {
//     label: string
//     description: string
//     value: string
//     cta: string
// }

export const AboutUs = () => {
    // const { label, description, value, cta } = props
    return (
        <Box bg="custom.100">
            <Container py={{ base: '16', md: '24' }}>
                <Stack spacing={{ base: '12', md: '16' }} textAlign="center" align="center">
                    <Stack spacing={{ base: '4', md: '5' }}>
                        <Heading size={{ base: 'sm', md: 'md' }}>Swap Tokens for Land</Heading>
                        {/* <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted" maxW="3xl">
                            To foster a nationwide ecosystem of AI developers, enthusiasts, and researchers dedicated to building a decentralized and open AI infrastructure, ensuring that AI benefits all sectors of Australian society equally.
                        </Text> */}
                        <Center>
                            <Box alignContent='center'>
                                {/* <Image borderRadius='lg' src='/assets/swappingtokensforland.webp' alt='Track Tace Trade' /> */}
                            </Box>

                        </Center>
                        <Text color="fg.accent.muted" fontSize={{ base: 'lg', md: 'xl' }} maxW="3xl">
                            Invest in land through a straightforward token exchange system. Our digital platform ensures
                            a smooth transition from cryptocurrency to tangible land ownership, empowering you with immediate asset control.</Text>


                    </Stack>
                    {/* <Spacer /> */}

                    <Stack spacing={{ base: '4', md: '5' }}>
                        <Heading size={{ base: 'sm', md: 'md' }}>Join the Waiting List</Heading>
                        <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted" maxW="4xl">
                            Monitor your land assets with precision. Our comprehensive tracking system allows you to oversee every aspect of your land ownership from acquisition through to management, ensuring every transaction is recorded and easily accessible.
                        </Text>
                        <ChakraLink href="">
                            <Button bg="custom.200" size="lg" boxShadow='dark-lg'>Learn More</Button>
                        </ChakraLink>
                    </Stack>


                    {/* <Stack
          direction={{ base: 'column', md: 'row' }}
          maxW="3xl"
          width="full"
          spacing={{ base: '8', md: '4' }}
          {...(!isMobile ? { divider: <StackDivider /> } : {})}
        >
          {stats.map((stat, id) => (
            <Stat key={id} flex="1" {...stat} />
          ))}
        </Stack> */}
                </Stack>
            </Container>
        </Box>
    )
}